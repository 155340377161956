/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {actions as modalActions} from './redux/ModalRedux'
import {useNavigate} from 'react-router-dom'

type Props = {
  show: boolean
}

const PaymentProcessing: FC<Props> = ({show = false}) => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        console.log('closing modal')
        dispatch(modalActions.showPaymentProcessing(false))
        navigate(0)
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [show])

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Payment Processing</Modal.Title>
      </Modal.Header>
      <Modal.Body>You payment is processing. Please wait.</Modal.Body>
    </Modal>
  )
}

export {PaymentProcessing}
