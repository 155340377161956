import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as lead from '../../app/modules/leads'
import * as stats from '../../app/pages/dashboard'
import * as customize from '../../app/modules/customize'
import * as subscriber from '../../app/modules/subscriber'
import * as payment from '../../app/modules/payment'
import * as modal from '../../_metronic/partials/modals/redux/ModalRedux'
import * as prompts from '../../app/modules/prompts'
import * as train from '../../app/modules/train'
import * as paddle from '../../app/modules/paddle'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  subscriber: subscriber.reducer,
  leads: lead.reducer,
  stats: stats.reducer,
  customize: customize.reducer,
  payment: payment.reducer,
  modal: modal.reducer,
  prompts: prompts.reducer,
  train: train.reducer,
  paddle: paddle.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    lead.saga(),
    stats.saga(),
    customize.saga(),
    subscriber.saga(),
    payment.saga(),
    prompts.saga(),
    train.saga(),
    paddle.saga(),
  ])
}
