import {Action} from '@reduxjs/toolkit'

import {put, takeLatest, select} from 'redux-saga/effects'
import {getSettings, putSettings, uploadLogo} from './SettingsCRUD'
import {SettingsModel} from '../models/SettingsModel'
import {toast} from 'react-toastify'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components/_ScrollTopComponent'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}
const emptyFile = new File([], 'empty')

export const actionTypes = {
  // Get settings
  GetSettings: '[GetSettings] Action',
  // Update settings on redux state
  UpdateCustomizeState: '[UpdateCustomizationState] Action',
  // Update settings in backend
  SaveSettingsInBackend: '[SaveSettingsInBackend] Action',
  UploadLogo: '[UploadLogo] Action',
}

export interface ICustomizeState {
  settings: SettingsModel
  logo: File
  iframeKey: Number
}

const initialSettingsState: ICustomizeState = {
  settings: {
    id: '',
    subscriberId: '',
    env: {
      SHOW_HEADER: false,
      SHOW_BRAND_LOGO: false,
      HEADER_TEXT: '',
    },
    // Random defaults before fetching from API
    theme: {
      HEADER_BG: '#097969',
      HEADER_TEXT_COLOR: '#ffffff',
      BOT_TEXTBOX_BG: '#e5e5ea',
      BOT_TEXTBOX_COLOR: '#000000',
      BOT_TYPING_COLOR: '#ed7325',
      PROMPT_COLOR: '#ffffff',
      PROMPT_BG: '#ed7325',
      PROMPT_HOVER_BG: '#ed4125',
      GUEST_TEXTBOX_BG: '#ed7325',
      GUEST_TEXTBOX_COLOR: '#ffffff',
      SUBSCRIBER_LOGO_BG: '#ffffff',
      BACKGROUND_IMAGE_URL: 'https://cdn.engage.ninja/common/background_1.png',
    },
  },
  logo: emptyFile,
  iframeKey: 0,
}

export const reducer = (
  state: ICustomizeState = initialSettingsState,
  action: ActionWithPayload<ICustomizeState>
) => {
  switch (action.type) {
    case actionTypes.UpdateCustomizeState: {
      return {...state, ...action.payload}
    }
    default:
      return state
  }
}

export const actions = {
  getSettings: () => ({type: actionTypes.GetSettings}),
  // Does not update on the backend. Update the redux state
  updateCustomization: (customization: ICustomizeState) => ({
    type: actionTypes.UpdateCustomizeState,
    payload: customization,
  }),
  saveSettingsInBackend: () => ({
    type: actionTypes.SaveSettingsInBackend,
  }),
  uploadLogo: () => ({
    type: actionTypes.UploadLogo,
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.GetSettings,
    function* getSettingsFunc(action: ActionWithPayload<any>) {
      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
          customize: state.customize,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)

      const {data: settings} = yield getSettings(selectedState.subscriberId)

      yield put(actions.updateCustomization({...selectedState.customize, settings}))
    }
  )

  /**
   * Updates settings in backend -> Updates settings state
   */
  yield takeLatest(
    actionTypes.SaveSettingsInBackend,
    function* putSettingsFunc(action: ActionWithPayload<any>) {
      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
          customize: state.customize,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)

      var toastId
      try {
        // Upload logo file
        if (selectedState.customize.logo && selectedState.customize.logo.name != 'empty') {
          toastId = toast.loading('Uploading logo...', {autoClose: 2000, theme: 'colored'})
          const {data: fileUploadResponse} = yield uploadLogo(
            selectedState.subscriberId,
            selectedState.customize.logo,
            (progress: any) => console.log(progress)
          )

          toast.update(toastId, {
            render: 'Uploaded logo',
            type: 'success',
            isLoading: false,
            autoClose: 2000,
          })

          selectedState.customize.settings.env['APP_LOGO'] = fileUploadResponse.filepath
        }

        toastId = toast.loading('Saving settings...', {autoClose: 2000, theme: 'colored'})

        // Update the settings in server
        const {data: settingsReponse} = yield putSettings(
          selectedState.subscriberId,
          selectedState.customize.settings
        )

        // Reset file to empty file and update settings with saved response
        yield put(
          actions.updateCustomization({
            ...selectedState.customize,
            logo: emptyFile,
            settings: settingsReponse,
            iframeKey: selectedState.customize.iframeKey + 1,
          })
        )

        toast.update(toastId, {
          render: 'Saved settings',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
      } catch (error) {
        toast.update(toastId, {
          render: 'An error occurred.',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
      } finally {
        ScrollTopComponent.goTop()
      }
    }
  )

  /**
   * Uploads logo -> Updates Settings in backend
   */
  yield takeLatest(
    actionTypes.UploadLogo,
    function* uploadLogoFunc(action: ActionWithPayload<any>) {
      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
          customize: state.customize,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)

      const id = toast.loading('Uploading logo...', {autoClose: 2000})

      try {
        const {data: response} = yield uploadLogo(
          selectedState.subscriberId,
          selectedState.customize.logo,
          (progress: any) => console.log(progress)
        )

        toast.update(id, {
          render: 'Uploaded logo',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })

        let settings = selectedState.settings

        // yield put(
        //   actions.putSettings({
        //     ...settings,
        //     env: {
        //       ...settings.env,
        //       APP_LOGO: response.filepath,
        //     },
        //   })
        // )
      } catch (error) {
        toast.update(id, {
          render: 'An error occurred.',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
      } finally {
        ScrollTopComponent.goTop()
      }
    }
  )
}
