import * as React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

import Form from 'react-bootstrap/Form'
import {RootState} from '../../../setup'
import {IAuthState} from '../auth'
import {useEffect, useState} from 'react'
import {Button, InputGroup} from 'react-bootstrap'
import {BsX} from 'react-icons/bs'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {IBlueprintState, actions} from './redux/PromptsRedux'
import {useDebouncedCallback} from 'use-debounce'

export interface IPromptsPageProps {}

// enum PROMPT_TYPE {
//   FREE_FORM = 'FREE_FORM',
//   CHOICES = 'CHOICES',
// }

export default function PromptsPage(props: IPromptsPageProps) {
  const dispatch = useDispatch()
  const authState: IAuthState = useSelector<RootState>(({auth}) => auth, shallowEqual) as IAuthState

  const promptsState: IBlueprintState = useSelector<RootState>(
    ({prompts}) => prompts,
    shallowEqual
  ) as IBlueprintState

  useEffect(() => {
    dispatch(actions.getBlueprint())
  }, [dispatch])

  const [choice, setChoice] = useState<string>('')

  const updateInBackend = useDebouncedCallback(() => {
    dispatch(actions.updateBlueprintInBackend())
  }, 1000)

  const setQuestion = (question: string) => {
    dispatch(
      actions.updateBlueprintState({
        data: {
          ...promptsState.data,
          bubble_data: question,
        },
        iframeKey: promptsState.iframeKey,
      })
    )
    updateInBackend()
  }

  const setPlaceholder = (placeholder: string) => {
    dispatch(
      actions.updateBlueprintState({
        data: {
          ...promptsState.data,
          placeholder: placeholder,
        },
        iframeKey: promptsState.iframeKey,
      })
    )
    updateInBackend()
  }

  const addChoice = () => {
    if (choice && choice.length > 0) {
      dispatch(
        actions.updateBlueprintState({
          data: {
            ...promptsState.data,
            suggestions: [
              ...(promptsState.data.suggestions ? promptsState.data.suggestions : []),
              choice,
            ],
          },
          iframeKey: promptsState.iframeKey,
        })
      )
    }
    setChoice('')
    updateInBackend()
  }

  const removeChoice = (e: React.MouseEvent<HTMLButtonElement>) => {
    var array: string[] = [...promptsState.data.suggestions] // make a separate copy of the array
    var index = promptsState.data.suggestions.indexOf(e.currentTarget.name)
    if (index !== -1) {
      array.splice(index, 1)
      dispatch(
        actions.updateBlueprintState({
          data: {
            ...promptsState.data,
            suggestions: array,
          },
          iframeKey: promptsState.iframeKey,
        })
      )
      updateInBackend()
    }
  }

  const getChoicesComponent = () => {
    let choiceComponents: JSX.Element[] = []
    if (!promptsState.data.suggestions || promptsState.data.suggestions.length === 0) {
      return choiceComponents
    }

    promptsState.data.suggestions.map((choice, index) => {
      choiceComponents.push(
        <Button
          key={index}
          variant='primary'
          size='sm'
          className='me-3 mb-3'
          name={choice}
          onClick={removeChoice}
        >
          {choice} <BsX></BsX>
        </Button>
      )
    })
    return choiceComponents
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Prompts'}</PageTitle>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div className='row mt-5'>
            {/* begin::Form */}
            <div className='col-md-8'>
              <Form>
                <div className='form-box mb-6'>
                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Intro message</Form.Label>
                    <Form.Control
                      required
                      type='textarea'
                      placeholder='Hi, I am your favourite AI assistant'
                      value={promptsState.data.bubble_data}
                      onChange={(e) => setQuestion(e.target.value)}
                    />
                  </Form.Group>

                  {/* <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>User response type</Form.Label>
                    <Form.Check
                      onChange={onLabelChange}
                      checked={freeForm === PROMPT_TYPE.FREE_FORM}
                      className='mb-3'
                      label='Text Input'
                      name='FREE_FORM'
                      type='radio'
                      id='inline-radio-1'
                    />
                    <Form.Check
                      onChange={onLabelChange}
                      checked={freeForm === PROMPT_TYPE.CHOICES}
                      label='Select from options'
                      name='CHOICES'
                      type='radio'
                      id='inline-radio-2'
                    />
                  </Form.Group> */}

                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Input placeholder</Form.Label>
                    <Form.Control
                      type='textarea'
                      placeholder='Ask me anything...'
                      value={promptsState.data.placeholder}
                      onChange={(e) => setPlaceholder(e.target.value)}
                    />
                  </Form.Group>
                  {/* {getSecondForm()} */}
                </div>
                <div className='form-box mb-6'>
                  <Form.Group className='mb-3' controlId='choices'>
                    <Form.Label>Prompts</Form.Label>
                    <InputGroup className='mb-3'>
                      <Form.Control
                        placeholder='What is your pricing plan ?'
                        aria-label='What is your pricing plan ?'
                        aria-describedby='basic-addon2'
                        value={choice}
                        onChange={(e) => setChoice(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            addChoice()
                          }
                        }}
                      />
                      <Button variant='primary' id='button-addon2' onClick={addChoice}>
                        Add prompt
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  {getChoicesComponent()}
                </div>
              </Form>
            </div>
            {/* end::Form */}
            {/* begin::LiveContainer */}
            <div className='col-md-4'>
              <div className='live-ui-container'>
                <iframe
                  key={promptsState.iframeKey.toString()}
                  src={`${process.env.REACT_APP_ENGAGE_URL}/chat-assist.html?subscriberId=${authState.user?.subscriberId}&user_graph_id=landing`}
                ></iframe>
              </div>
            </div>
            {/* end::LiveContainer */}
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}
