/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {useFormik} from 'formik'
import {ILeadsState} from './redux/LeadsRedux'
import {actions} from './redux/LeadsRedux'
import {LeadModel} from './models/LeadModel'
import {PageTitle} from '../../../_metronic/layout/core'
import LeadRow from './LeadRow'

const API_URL = process.env.REACT_APP_API_URL

const LeadsPage: React.FC = () => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {fromDate: '', toDate: ''},
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      console.log(values)
      dispatch(actions.downloadCSV(values.fromDate, values.toDate))
      setSubmitting(false)
      setTimeout(() => {
        resetForm()
      }, 1000)
    },
  })

  const leads: ILeadsState = useSelector<RootState>(({leads}) => leads, shallowEqual) as ILeadsState

  useEffect(() => {
    dispatch(actions.fetchPage(1))
  }, [dispatch])

  const changePage = (page: number) => dispatch(actions.fetchPage(page))

  const getPageList = () => {
    let elements: JSX.Element[] = []
    leads.visiblePageList.forEach((page) => {
      elements.push(
        <li key={page} className={page === leads.currentPage ? 'page-item active' : 'page-item'}>
          <a href='#' className='page-link' onClick={() => changePage(page)}>
            {page}
          </a>
        </li>
      )
    })
    return elements
  }

  const getNextPageButton = () => {
    if (leads.visiblePageList[leads.visiblePageList.length - 1] < leads.totalPages) {
      return (
        <li className='page-item next'>
          <a href='#' onClick={() => changePage(++leads.currentPage)} className='page-link'>
            <i className='next'></i>
          </a>
        </li>
      )
    }
  }

  const getPreviousPageButton = () => {
    if (leads.visiblePageList[0] > 1) {
      return (
        <li className='page-item previous'>
          <a href='#' onClick={() => changePage(--leads.currentPage)} className='page-link'>
            <i className='previous'></i>
          </a>
        </li>
      )
    }
  }

  const getTableHeader = () => {
    let elements: JSX.Element[] = []
    if (leads.columns && leads.columns.length > 0) {
      leads.columns.forEach((column, index: number) => {
        let classes = 'min-w-150px'
        elements.push(
          <th key={index} className={classes}>
            {column.charAt(0).toUpperCase() + column.slice(1)}
          </th>
        )
      })
    }
    return elements
  }

  const getEmptyTable = () => {
    if (!leads.leads || leads.leads.length == 0) {
      return (
        <div className='row justify-content-md-center mt-20 mb-20'>
          <div className='col-md-auto'>Just getting started</div>
        </div>
      )
    }
  }

  const getRows = () => {
    let elements: JSX.Element[] = []
    if (leads.leads && leads.leads.length > 0) {
      leads.leads.forEach((lead: LeadModel, index: number) => {
        elements.push(
          <LeadRow
            key={index}
            lead={lead}
            columns={leads.columns}
            index={`${leads.currentPage}-${index}`}
          ></LeadRow>
        )
      })
      return elements
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Leads'}</PageTitle>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Leads</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
          <div className='card-toolbar'>
            <form className='form form-inline flex-row d-flex' onSubmit={formik.handleSubmit}>
              <input
                className='form-control me-3'
                type='date'
                max={formik.values.toDate}
                {...formik.getFieldProps('fromDate')}
              />
              <input
                className='form-control me-3'
                type='date'
                min={formik.values.fromDate}
                {...formik.getFieldProps('toDate')}
              />
              <button
                className='btn btn-sm btn-primary me-3 w-75'
                type='submit'
                disabled={formik.isSubmitting}
              >
                Download CSV
              </button>
            </form>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {getEmptyTable()}
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>{getTableHeader()}</tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>{getRows()}</tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6 fw-bold text-gray-700'>
              Showing {(leads.currentPage - 1) * leads.leadsPerPage + 1} to{' '}
              {(leads.currentPage - 1) * leads.leadsPerPage + 10} of {leads.totalLeads} entries
            </div>

            <ul className='pagination'>
              {getPreviousPageButton()}
              {getPageList()}
              {getNextPageButton()}
            </ul>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}
export default LeadsPage
