import * as React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

import Form from 'react-bootstrap/Form'
import {RootState} from '../../../setup'
import {IAuthState} from '../auth'
import {useEffect, useState} from 'react'
import {Button, InputGroup} from 'react-bootstrap'
import {BsX} from 'react-icons/bs'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {ITrainState, actions} from './redux/TrailRedux'
import {useDebouncedCallback} from 'use-debounce'
import JobComponent from './JobComponent'
import {JOB_STAGE} from './models/JobModel'

export interface ITrainPagePrompts {}

export default function PromptsPage(props: ITrainPagePrompts) {
  const dispatch = useDispatch()
  const pro: IAuthState = useSelector<RootState>(({auth}) => auth, shallowEqual) as IAuthState

  const trainState: ITrainState = useSelector<RootState>(
    ({train}) => train,
    shallowEqual
  ) as ITrainState

  useEffect(() => {
    dispatch(actions.getJobs())
  }, [dispatch])

  const updateUrl = (url: string) => {
    dispatch(
      actions.updateJobsState({
        ...trainState,
        inputUrl: url,
      })
    )
  }

  // If we have a running job, keep fetching status.
  if (
    trainState.jobs &&
    trainState.jobs.length > 0 &&
    trainState.jobs[0].stage !== JOB_STAGE.FAILED &&
    trainState.jobs[0].stage !== JOB_STAGE.TRAINING_SUCCESSFUL
  ) {
    setTimeout(() => {
      dispatch(actions.getJobs())
    }, 2000)
  }

  const startTraining = () => {
    dispatch(actions.createJob())
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Train'}</PageTitle>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body'>
          <div className='row'>
            {/* begin::Form */}
            <div className='col-md-12'>
              <Form>
                <div className='form-box'>
                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Website URL</Form.Label>
                    <InputGroup className='mb-3'>
                      <Form.Control
                        placeholder='https://react.dev/blog'
                        aria-label='https://react.dev/blog'
                        aria-describedby='basic-addon2'
                        value={trainState.inputUrl}
                        onChange={(e) => updateUrl(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            startTraining()
                            e.preventDefault()
                          }
                        }}
                      />
                      <Button variant='primary' id='button-addon2' onClick={startTraining}>
                        Start Training
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div>
              </Form>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-md-12'>
              {trainState.jobs && trainState.jobs.length > 0 && (
                <JobComponent job={trainState.jobs?.[0]}></JobComponent>
              )}
            </div>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}
