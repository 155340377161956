import axios from 'axios'
import {JobModel} from '../models/JobModel'

const API_URL = process.env.REACT_APP_API_URL

// Get prompts
export function getJobs(subscriberId: string) {
  return axios.get<JobModel[]>(`${API_URL}/Subscribers/${subscriberId}/jobs`, {
    params: {
      'filter[limit]': 1,
      'filter[order]': 'created_on DESC',
    },
  })
}

// Post (update) prompts
export function createJob(subscriberId: string, job: JobModel) {
  return axios.post<JobModel>(`${API_URL}/Subscribers/${subscriberId}/jobs`, job)
}
