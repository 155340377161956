import React, { useCallback } from "react";

const MultilineEdit = ({ value, setValue }) => {
  const [editingValue, setEditingValue] = React.useState(value);

  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    setValue(event.target.value);
  };

  const onInput = useCallback((target) => {
    if (target.scrollHeight > 33) {
      target.style.height = "5px";
      target.style.height = target.scrollHeight - 16 + "px";
    }
  }, []);

  const textareaRef = React.useRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    onInput(textareaRef.current);
  }, [onInput, textareaRef]);

  return (
    <textarea
      rows={1}
      aria-label="Field name"
      value={editingValue}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onInput={(event) => onInput(event.target)}
      placeholder='Click to edit'
      // @ts-ignore
      ref={textareaRef}
    />
  );
};
export default MultilineEdit;