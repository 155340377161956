/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {shallowEqual, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {ISubscriberState} from '../../../app/modules/subscriber'
import {PLAN_STATUS} from '../../../app/modules/subscriber/models/SubscriptionModel'
import {RootState} from '../../../setup'

import {StepperComponent} from '../../assets/ts/components'

const TrialExpired: FC = () => {
  const handleClose = () => setShow(false)
  const [show, setShow] = useState<boolean>(false)
  const navigate = useNavigate()

  const gotoAccount = () => {
    setShow(false)
    navigate('/account')
  }

  const subscriberState: ISubscriberState = useSelector<RootState>(
    ({subscriber}) => subscriber,
    shallowEqual
  ) as ISubscriberState

  useEffect(() => {
    if (subscriberState.subscriber?.subscription.status == PLAN_STATUS.TRIAL_EXPIRED) {
      setShow(true)
    }
  }, [subscriberState.subscriber])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Your Trial has expired</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please upgrade your account to continue using Engage.</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={gotoAccount}>
          Upgrade Account
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {TrialExpired}
