/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {ICustomizeState} from './redux/SettingsRedux'
import {actions} from './redux/SettingsRedux'
import {PageTitle} from '../../../_metronic/layout/core'
import {ColorChangeInputGroup} from '../../reactUtils/ColorChangeInputGroup'
import {IAuthState} from '../auth'
import {useDebouncedCallback} from 'use-debounce'

const API_URL = process.env.REACT_APP_API_URL

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``)
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16)
    let r = (decimalColor >> 16) + magnitude
    r > 255 && (r = 255)
    r < 0 && (r = 0)
    let g = (decimalColor & 0x0000ff) + magnitude
    g > 255 && (g = 255)
    g < 0 && (g = 0)
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude
    b > 255 && (b = 255)
    b < 0 && (b = 0)
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`
  } else {
    return hexColor
  }
}

const CustomizePage: React.FC = () => {
  const dispatch = useDispatch()

  const logoInputRef = useRef<HTMLInputElement>(null)

  const customizeState: ICustomizeState = useSelector<RootState>(
    ({customize}) => customize,
    shallowEqual
  ) as ICustomizeState

  const authState: IAuthState = useSelector<RootState>(({auth}) => auth, shallowEqual) as IAuthState

  const settings = customizeState.settings

  const updateInBackend = useDebouncedCallback(() => {
    dispatch(actions.saveSettingsInBackend())
  }, 1000)

  function logoSelectHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target
    if (target.files) {
      const file = target.files[0]
      dispatch(
        actions.updateCustomization({
          ...customizeState,
          logo: file,
        })
      )
      updateInBackend()
    }
  }
  function getLogoPreview() {
    if (customizeState.logo && customizeState.logo.name != 'empty') {
      let src = URL.createObjectURL(customizeState.logo)
      return <img src={src} />
    } else {
      return <img src={settings.env['APP_LOGO']} />
    }
  }

  const setColor = (property, color) => {
    dispatch(
      actions.updateCustomization({
        ...customizeState,
        settings: {
          ...settings,
          theme: {
            ...settings.theme,
            // Bot typing color will be same as bot background color
            BOT_TYPING_COLOR: settings.theme['BOT_TEXTBOX_BG'],
            PROMPT_HOVER_BG: newShade(settings.theme['PROMPT_BG'], -35),
            [property]: color,
          },
        },
      })
    )
    updateInBackend()
  }

  function setEnv(property, value) {
    dispatch(
      actions.updateCustomization({
        ...customizeState,
        settings: {
          ...settings,
          env: {
            ...settings.env,
            [property]: value,
          },
        },
      })
    )
    updateInBackend()
  }

  function saveSettings() {
    // if (logo) {
    //   // Upload logo will complete and dispatch putSettings after
    //   // uploading logo.
    //   dispatch(actions.uploadLogo((logo as FileUpload).file))
    // } else {
    // If logo has not changed, update settings directly.
    dispatch(actions.saveSettingsInBackend())
    // }
  }

  useEffect(() => {
    dispatch(actions.getSettings())
  }, [dispatch])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Customize'}</PageTitle>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div className='row mt-5'>
            {/* begin::Form */}
            <div className='col-md-8'>
              <Form>
                {/* Header settings */}
                <div className='form-box mb-6'>
                  <Form.Group className='mb-6' controlId='formBasicCheckbox'>
                    <Form.Check
                      type='checkbox'
                      label='Show Header'
                      checked={settings.env['SHOW_HEADER']}
                      onChange={(e) => setEnv('SHOW_HEADER', e.target.checked)}
                    />
                  </Form.Group>

                  <Form.Group className='mb-6' controlId='formBasicEmail'>
                    <Form.Label>Header text value</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Chat Header'
                      value={settings.env['HEADER_TEXT']}
                      onChange={(e) => setEnv('HEADER_TEXT', e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className='mb-6' controlId='formBasicPassword'>
                    <Form.Label>Header text color</Form.Label>
                    <ColorChangeInputGroup
                      property={'HEADER_TEXT_COLOR'}
                      color={settings.theme['HEADER_TEXT_COLOR']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>

                  <Form.Group className='mb-2' controlId='formBasicPassword'>
                    <Form.Label>Header background color</Form.Label>
                    <ColorChangeInputGroup
                      property={'HEADER_BG'}
                      color={settings.theme['HEADER_BG']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>
                </div>

                {/* Logo settings */}
                <div className='form-box mb-6'>
                  <Form.Group className='mb-6' controlId='formBasicCheckbox2'>
                    <Form.Check
                      type='checkbox'
                      label='Show Brand logo'
                      checked={settings.env['SHOW_BRAND_LOGO']}
                      onChange={(e) => setEnv('SHOW_BRAND_LOGO', e.target.checked)}
                    />
                  </Form.Group>

                  <Form.Group className='mb-6'>
                    <Form.Label>Brand logo (Max size 5mb)</Form.Label>
                    <input
                      type='file'
                      accept='image/png, image/gif, image/jpeg'
                      ref={logoInputRef}
                      onChange={logoSelectHandler}
                      hidden
                    ></input>
                    <div className='uploader'>
                      <div className='logo-preview'>{getLogoPreview()}</div>
                      <div className='image-upload-button'>
                        <Button
                          variant='primary'
                          id='button-addon2'
                          onClick={() => {
                            logoInputRef.current?.click()
                          }}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Brand logo background color</Form.Label>
                    <ColorChangeInputGroup
                      property={'SUBSCRIBER_LOGO_BG'}
                      color={settings.theme['SUBSCRIBER_LOGO_BG']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>
                </div>

                {/* Bot settings */}
                <div className='form-box mb-6'>
                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Bot message text color</Form.Label>
                    <ColorChangeInputGroup
                      property={'BOT_TEXTBOX_COLOR'}
                      color={settings.theme['BOT_TEXTBOX_COLOR']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>

                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Bot message background color</Form.Label>
                    <ColorChangeInputGroup
                      property={'BOT_TEXTBOX_BG'}
                      color={settings.theme['BOT_TEXTBOX_BG']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>
                </div>

                {/* Guest settings */}
                <div className='form-box mb-6'>
                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Guest message text color</Form.Label>
                    <ColorChangeInputGroup
                      property={'GUEST_TEXTBOX_COLOR'}
                      color={settings.theme['GUEST_TEXTBOX_COLOR']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>

                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Guest message background color</Form.Label>
                    <ColorChangeInputGroup
                      property={'GUEST_TEXTBOX_BG'}
                      color={settings.theme['GUEST_TEXTBOX_BG']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>
                </div>

                {/* Option settings */}
                <div className='form-box mb-6'>
                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Option button text color</Form.Label>
                    <ColorChangeInputGroup
                      property={'PROMPT_COLOR'}
                      color={settings.theme['PROMPT_COLOR']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>

                  <Form.Group className='mb-3' controlId='formBasicPassword'>
                    <Form.Label>Option button background color</Form.Label>
                    <ColorChangeInputGroup
                      property={'PROMPT_BG'}
                      color={settings.theme['PROMPT_BG']}
                      setColor={setColor}
                    ></ColorChangeInputGroup>
                  </Form.Group>
                </div>

                {/* <Button variant='primary' onClick={saveSettings}>
                  Save
                </Button> */}
              </Form>
            </div>
            {/* end::Form */}
            {/* begin::LiveContainer */}
            <div className='col-md-4'>
              <div className='live-ui-container'>
                <iframe
                  key={customizeState.iframeKey.toString()}
                  src={`${process.env.REACT_APP_ENGAGE_URL}/chat-assist.html?subscriberId=${authState.user?.subscriberId}&user_graph_id=landing`}
                ></iframe>
              </div>
            </div>
            {/* end::LiveContainer */}
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}
export default CustomizePage
