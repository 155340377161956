import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {AuthToken} from '../models/AuthToken'
import {useSearchParams} from 'react-router-dom'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const authTokenState = useSelector<RootState>(({auth}) => auth.authToken, shallowEqual)

  let authTokenStateCopy: AuthToken = authTokenState as AuthToken
  let [searchParams, setSearchParams] = useSearchParams()

  let authToken: string | null = searchParams.get('id')
  let userId: string | null = searchParams.get('userId')

  // If we have query params, override the state.
  if (userId && authToken) {
    authTokenStateCopy = {
      id: authToken,
      ttl: 1209600,
      created: new Date().toISOString(),
      userId: userId,
    }
  }

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          dispatch(props.login(authTokenStateCopy))
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    // If no query params are present, look for existing state
    if (authTokenStateCopy) {
      console.log('Token present, requesting user')

      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
