import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {reduxBatch} from '@manaflair/redux-batch'
import {persistStore, persistReducer} from 'redux-persist'
import {rootReducer, rootSaga} from './RootReducer'
import storage from 'redux-persist/lib/storage'
import {actions as modalActions} from '../../_metronic/partials/modals/redux/ModalRedux'

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
]

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})

export type AppDispatch = typeof store.dispatch

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

//@ts-ignore
window.checkoutComplete = function (data: PaddleCallback) {
  // Show paddle payment processing modal
  console.log('paddle payment success. show modal')
  store.dispatch(modalActions.showPaymentProcessing(true))
}

//@ts-ignore
window.checkoutClosed = function (data: PaddleCallback) {
  // do nothing
}

export default store
