import axios from 'axios'
import {SubscriberModel} from '../../subscriber/models/SubscriberModel'
import {RazorpaySuccesshandlerArgs} from '../models/RazorpayTypes'

const API_URL = process.env.REACT_APP_API_URL

const RPAY_PLAN_ID = process.env.REACT_APP_RPAY_PAYMENT_PLAN

// TODO: get rpay_plan_id from backend
export function createRPaySubscription(id: string) {
  return axios.post<SubscriberModel>(
    `${API_URL}/Subscribers/${id}/createRPaySubscription/${RPAY_PLAN_ID}`
  )
}

export function verifyRPaySubscription(id: string, body: RazorpaySuccesshandlerArgs) {
  return axios.post<SubscriberModel>(`${API_URL}/Subscribers/${id}/verify-payment`, body)
}

export function cancelRPaySubscription(id: string) {
  return axios.post<SubscriberModel>(`${API_URL}/Subscribers/${id}/cancel-subscription`)
}
