import axios from 'axios'
import {PromptsModel} from '../models/BlueprintModel'

const API_URL = process.env.REACT_APP_API_URL

// Get prompts
export function getBlueprint(subscriberId: string) {
  return axios.get<PromptsModel>(`${API_URL}/Subscribers/${subscriberId}/get-llm-prompts`)
}

// Post (update) prompts
export function postBlueprint(subscriberId: string, prompts: PromptsModel) {
  return axios.post<PromptsModel>(
    `${API_URL}/Subscribers/${subscriberId}/update-llm-prompts`,
    prompts
  )
}
