import axios from 'axios'
import {LeadModel} from '../models/LeadModel'

const API_URL = process.env.REACT_APP_API_URL

// Get leads by page
export function getLeadsByPage(subscriberId: string, requestedPage: number) {
  return axios.get<LeadModel[]>(`${API_URL}/Subscribers/${subscriberId}/guests`, {
    params: {
      'filter[limit]': 10,
      'filter[skip]': (requestedPage - 1) * 10,
      'filter[order]': 'created_on DESC',
      'filter[where][status]': 'lead',
    },
  })
}

// Update lead
export function updateLead(subscriberId: string, guestId: string, data: any) {
  return axios.put(`${API_URL}/Subscribers/${subscriberId}/guests/${guestId}`, data)
}

export function downloadCSV(subscriberId: string, from: number, to: number, access_token: string) {
  return axios.get(
    `${API_URL}/Subscribers/${subscriberId}/downloadCSV?from=${from}&to=${to}&access_token=${access_token}`
  )
}
