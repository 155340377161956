/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  statCount: number
  title: string
}

const StatisticsWidget7: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  statCount,
  title,
}) => {
  return (
    <a href='#' className={`card text-center bg-${color} hoverable ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        <div className={`text-inverse-${color} fw-bolder display-2 mb-2 mt-2`}>{statCount.toLocaleString()}</div>

        <div className={`text-inverse-${color} fs-2 mt-5`}>{title}</div>

      </div>
      {/* end::Body */}
    </a>
  )
}

export { StatisticsWidget7 }
