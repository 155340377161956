/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {ISubscriberState} from '../../../../app/modules/subscriber'
import {SUBSCRIBER_TYPE} from '../../../../app/modules/subscriber/models/SubscriberModel'

export function AsideMenuMain() {
  const intl = useIntl()

  const subscriberState: ISubscriberState = useSelector<RootState>(
    ({subscriber}) => subscriber,
    shallowEqual
  ) as ISubscriberState

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {subscriberState && subscriberState.subscriber?.type === SUBSCRIBER_TYPE.LEAD && (
        <AsideMenuItem
          to='/leads'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Leads'
          fontIcon='bi-layers'
        />
      )}
      {subscriberState && subscriberState.subscriber?.type === SUBSCRIBER_TYPE.LLM && (
        <AsideMenuItem
          to='/train'
          icon='/media/icons/duotune/abstract/abs023.svg'
          title='Train'
          fontIcon='bi-layers'
        />
      )}
      {/* <AsideMenuItem
        to='/build'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Build'
        fontIcon='bi-layers'
      /> */}
      {subscriberState && subscriberState.subscriber?.type === SUBSCRIBER_TYPE.LLM && (
        <AsideMenuItem
          to='/prompts'
          icon='/media/icons/duotune/communication/com003.svg'
          title='Prompts'
          fontIcon='bi-layers'
        />
      )}
      <AsideMenuItem
        to='/customize'
        icon='/media/icons/duotune/art/art007.svg'
        title='Customize'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/deploy'
        icon='/media/icons/duotune/coding/cod003.svg'
        title={intl.formatMessage({id: 'MENU.DEPLOY'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/account'
        icon='/media/icons/duotune/coding/cod001.svg'
        title='Account'
        fontIcon='bi-layers'
      />
    </>
  )
}
