/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {ISubscriberState} from '../../../app/modules/subscriber'
import {PLAN_STATUS} from '../../../app/modules/subscriber/models/SubscriptionModel'
import {RootState} from '../../../setup'
import {actions as paymentActions} from '../../../app/modules/payment'
import {actions as modalActions} from './redux/ModalRedux'

type Props = {
  show: boolean
}

const CancelSubscription: FC<Props> = ({show = false}) => {
  const dispatch = useDispatch()

  const cancelSubscription = () => {
    handleClose()
    dispatch(paymentActions.cancelSubscription())
  }

  const handleClose = () => {
    dispatch(modalActions.showCancelSubscription(false))
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to cancel ?</Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={cancelSubscription}>
          Cancel subscription
        </Button>
        <Button variant='primary' onClick={handleClose}>
          Keep my subscription
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {CancelSubscription}
