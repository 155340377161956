import React from 'react'
import {JOB_STAGE, JobModel} from './models/JobModel'
import './JobComponent.scss'
import Accordion from 'react-bootstrap/esm/Accordion'
import clsx from 'clsx'
import Spinner from 'react-bootstrap/esm/Spinner'

type Props = {
  job: JobModel | null
}

const getClassName = (stage: JOB_STAGE | undefined) => {
  if (!stage) {
    return ''
  }
  switch (stage) {
    case JOB_STAGE.CREATED:
      return 'created'
    case JOB_STAGE.CRAWL_IN_PROGRESS:
      return 'crawling'
    case JOB_STAGE.CRAWL_SUCCESSFUL:
      return 'crawl-sucess'
    case JOB_STAGE.TRAINING_IN_PROGRESS:
      return 'training'
    case JOB_STAGE.TRAINING_SUCCESSFUL:
      return 'train-success'
    case JOB_STAGE.FAILED:
      return 'failed'
  }
}
const JobComponent = (props: Props) => {
  const getUrlComponents = () => {
    let comps: JSX.Element[] = []
    if (!props.job || props.job.urls?.length === 0) {
      return comps
    }
    props.job?.urls?.forEach((url: string, index: number) => {
      comps.push(
        <div className='url' key={index}>
          {url}
        </div>
      )
    })
    return comps
  }

  const getSpinner = () => {
    if (
      props.job?.stage !== JOB_STAGE.FAILED &&
      props.job?.stage !== JOB_STAGE.TRAINING_SUCCESSFUL
    ) {
      return (
        <span className='spinner-border spinner-border-sm align-middle ms-2 text-primary'></span>
      )
    }
    return <></>
  }

  return (
    <div className='job-container'>
      <div className='d-flex align-items-center flex-row-fluid'>
        <span>
          <b>Status:&nbsp;</b>
        </span>
        <span className={'status ' + getClassName(props.job?.stage)}>{props.job?.stage}</span>
        {getSpinner()}
      </div>
      <div className='mt-3'>
        <b>Start time: </b> {new Date((props.job?.created_on ?? 0) * 1000).toString()}
      </div>
      <div className='mt-3'>
        <b>Base URL: </b> {props.job?.url}
      </div>
      {props.job && props.job.urls && props.job.urls.length > 0 && (
        <>
          <div className='mt-3'>
            <b>Crawled URLs</b>
          </div>
          <div className='urls-container mt-3'>{getUrlComponents()}</div>
        </>
      )}
    </div>
  )
}

export default JobComponent
