import {Action} from '@reduxjs/toolkit'

import {put, takeLatest, select} from 'redux-saga/effects'
import {getJobs, createJob} from './TrainCRUD'
import {JOB_STAGE, JobModel} from '../models/JobModel'
import {toast} from 'react-toastify'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components/_ScrollTopComponent'
import {AxiosResponse} from 'axios'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  // Get jobs
  GetJobs: '[GetJob] Action',
  UpdateJobsState: '[UpdateJobsState] Action',
  // Create job
  CreateJob: '[CreateJob] Action',
}

export interface ITrainState {
  jobs: JobModel[]
  iframeKey: number
  inputUrl: string
}

const initialTrainState: ITrainState = {
  jobs: [],
  iframeKey: 0,
  inputUrl: '',
}

export const reducer = (
  state: ITrainState = initialTrainState,
  action: ActionWithPayload<ITrainState>
) => {
  switch (action.type) {
    case actionTypes.UpdateJobsState: {
      return {...state, ...action.payload}
    }
    default:
      return state
  }
}

export const actions = {
  getJobs: () => ({type: actionTypes.GetJobs}),
  // Does not update on the backend. Update the redux state
  createJob: () => ({
    type: actionTypes.CreateJob,
  }),
  updateJobsState: (jobState: ITrainState) => ({
    type: actionTypes.UpdateJobsState,
    payload: jobState,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.GetJobs, function* getJobsFunc(action: ActionWithPayload<any>) {
    // @ts-ignore
    const stateSelectFn = (state) => {
      return {
        subscriberId: state.auth.user.subscriberId,
        train: state.train,
      }
    }
    // @ts-ignore
    let selectedState: any = yield select(stateSelectFn)

    try {
      const axiosResponse: AxiosResponse = yield getJobs(selectedState.subscriberId)

      const jobs: JobModel[] = axiosResponse.data

      yield put(
        actions.updateJobsState({
          jobs: jobs,
          iframeKey: 0,
          inputUrl: selectedState.train.inputUrl,
        })
      )
    } catch (e) {
      console.error(e)
    }
  })

  /**
   * Updates settings in backend -> Updates settings state
   */
  yield takeLatest(actionTypes.CreateJob, function* createJobFunc(action: ActionWithPayload<any>) {
    // @ts-ignore
    const stateSelectFn = (state) => {
      return {
        subscriberId: state.auth.user.subscriberId,
        train: state.train,
      }
    }
    // @ts-ignore
    let selectedState: any = yield select(stateSelectFn)

    var toastId
    try {
      toastId = toast.loading('Starting training ...', {autoClose: 2000, theme: 'colored'})

      // Check if URL is correct
      let trainState: ITrainState = selectedState.train
      let url
      try {
        url = new URL(trainState.inputUrl)
      } catch (_) {
        toast.update(toastId, {
          render: 'Invalid URL',
          type: 'error',
          isLoading: false,
          autoClose: 1000,
        })

        // Reset URL
        yield put(
          actions.updateJobsState({
            ...trainState,
            inputUrl: '',
          })
        )
        return
      }

      const {data: job} = yield createJob(selectedState.subscriberId, {
        url: trainState.inputUrl,
        stage: JOB_STAGE.CREATED,
      })

      // yield put(
      //   actions.createJob({
      //     job: {...selectedState.prompts.data, ...prompt},
      //     iframeKey: selectedState.prompts.iframeKey + 1,
      //   })
      // )

      yield put(
        actions.updateJobsState({
          ...trainState,
          inputUrl: '',
          jobs: [job],
        })
      )

      toast.update(toastId, {
        render: 'Saved',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      })
    } catch (error) {
      toast.update(toastId, {
        render: 'An error occurred.',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      })
    }
  })
}
