import {Action} from '@reduxjs/toolkit'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  ShowCancelSubscription: '[ShowCancelSubscription] Action',
  ShowPaymentProcessing: '[ShowPaymentProcessing] Action',
}

export interface IModalState {
  showCancelSubscription: boolean
  showPaymentProcessing: boolean
}

const initialModalState: IModalState = {
  showCancelSubscription: false,
  showPaymentProcessing: false,
}

export const reducer = (
  state: IModalState = initialModalState,
  action: ActionWithPayload<IModalState>
) => {
  switch (action.type) {
    case actionTypes.ShowCancelSubscription: {
      const showCancelSubscription = action.payload?.showCancelSubscription
      return {...state, showCancelSubscription}
    }
    case actionTypes.ShowPaymentProcessing: {
      console.log(`reduction action ${action.payload?.showPaymentProcessing}`)
      const showPaymentProcessing = action.payload?.showPaymentProcessing
      return {...state, showPaymentProcessing}
    }
    default:
      return state
  }
}

export const actions = {
  showCancelSubscription: (showCancelSubscription: boolean) => ({
    type: actionTypes.ShowCancelSubscription,
    payload: {showCancelSubscription},
  }),
  showPaymentProcessing: (showPaymentProcessing: boolean): ActionWithPayload<any> => ({
    type: actionTypes.ShowPaymentProcessing,
    payload: {showPaymentProcessing},
  }),
}
