/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {LeadModel} from './models/LeadModel'
import {actions} from './redux/LeadsRedux'
import MultilineEdit from './MultilineEdit'

type Props = {
  lead: LeadModel
  columns: string[]
  index: string
}

const LeadRow: FC<Props> = ({lead, columns, index}) => {
  const dispatch = useDispatch()

  const updateNote = (value: string) => {
    dispatch(actions.updateLead(lead.id, {notes: value}))
  }

  const getCellValue = (
    columnHeader: string,
    columnIndex: number,
    lastIndex: boolean,
    form: any
  ): JSX.Element => {
    if (lastIndex) {
      return (
        <td key={columnIndex}>
          <a href='#' className='text-dark text-hover-primary fs-6'>
            <MultilineEdit value={lead.notes} setValue={updateNote} />
          </a>
        </td>
      )
    }
    return <td key={columnIndex}>{form[columnHeader]}</td>
  }

  const getRow = () => {
    let columnElements: JSX.Element[] = []
    if (columns && columns.length > 0) {
      columns.forEach((column: string, colIndex: number) => {
        columnElements.push(
          getCellValue(column, colIndex, colIndex === columns.length - 1, lead.form)
        )
      })
    }
    return <tr key={index}>{columnElements}</tr>
  }
  return getRow()
}

export default LeadRow
