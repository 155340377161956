import {Action} from '@reduxjs/toolkit'

import {put, takeLatest, select, call} from 'redux-saga/effects'
import {getStats} from './StatsCRUD'
import {AggregateStatsModel, StatsModel} from '../models/StatsModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  // Fetch via API
  FetchStats: '[FetchStats] Action',
  // Set the page fetched via API
  SetStats: '[SetStats] Action',
}

const initialStatsState: IStatsState = {
  stats: [],
  aggregate: {today: 0, lastSevenDays: 0, lastThirtyDays: 0, total: 0},
}

export interface IStatsState {
  stats: StatsModel[]
  aggregate: AggregateStatsModel
}

export const reducer = (
  state: IStatsState = initialStatsState,
  action: ActionWithPayload<IStatsState>
) => {
  switch (action.type) {
    case actionTypes.SetStats: {
      return {...state, ...action.payload}
    }
    default:
      return state
  }
}

export const actions = {
  fetchStats: () => ({type: actionTypes.FetchStats}),
  setStats: (stats: StatsModel[], aggregate: AggregateStatsModel) => ({
    type: actionTypes.SetStats,
    payload: {stats, aggregate},
  }),
}

function addDays(date: Date, days: number) {
  date.setDate(date.getDate() + days)
  return date
}

function getDates(startDate: Date, stopDate: Date) {
  var dateArray: Date[] = []
  var currentDate = startDate
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate))
    currentDate = addDays(currentDate, 1)
  }
  return dateArray
}

export function* saga() {
  yield takeLatest(
    actionTypes.FetchStats,
    function* statsRequested(action: ActionWithPayload<any>) {
      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)
      const {data: response, headers} = yield getStats(selectedState.subscriberId)

      var today: Date = new Date()
      var priorDate: Date = new Date(new Date().setDate(today.getDate() - 30))

      const dates: Date[] = getDates(priorDate, today)

      const stats: StatsModel[] = dates.map((date: Date) => {
        let stat: StatsModel = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          count: 0,
        }
        if (response.stats[`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`]) {
          stat.count =
            response.stats[`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`]
        }
        return stat
      })

      const aggregate: AggregateStatsModel = {
        today: 0,
        lastSevenDays: 0,
        lastThirtyDays: 0,
        total: 0,
      }

      for (let i = 0; i < stats.length; i++) {
        aggregate.lastThirtyDays = aggregate.lastThirtyDays + stats[i].count

        if (i >= 23) {
          aggregate.lastSevenDays = aggregate.lastSevenDays + stats[i].count
        }

        if (i === stats.length - 1) {
          aggregate.today = stats[i].count
        }
      }

      if (aggregate.total !== undefined) {
        aggregate.total = response.total
      }

      yield put(actions.setStats(stats, aggregate))
    }
  )
}
