import axios from 'axios'
import { StatsModel } from '../models/StatsModel'

const API_URL = process.env.REACT_APP_API_URL

// Get leads by page
export function getStats(subscriberId: string) {
  return axios.get<StatsModel[]>(`${API_URL}/Subscribers/${subscriberId}/getGuestStats`,
    {
      params: { "status": "lead" }
    })
}