import axios from 'axios'
import {SettingsModel} from '../models/SettingsModel'

const API_URL = process.env.REACT_APP_API_URL

// Get settings
export function getSettings(subscriberId: string) {
  return axios.get<SettingsModel>(`${API_URL}/Subscribers/${subscriberId}/settings`)
}

// Put (update) settings
export function putSettings(subscriberId: string, settings: SettingsModel) {
  return axios.put<SettingsModel>(
    `${API_URL}/Subscribers/${subscriberId}/settings?deploy=1`,
    settings
  )
}

export function uploadLogo(subscriberId: string, file: File, onUploadProgress) {
  let formData = new FormData()

  formData.append('file', file)

  return axios.post(`${API_URL}/Subscribers/${subscriberId}/logoUpload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  })
}
