import {SubscriptionModel} from './SubscriptionModel'

export enum SUBSCRIBER_TYPE {
  LEAD = 'LEAD',
  LLM = 'LLM',
}

export interface PaddleModel {
  subscription_id: string
  cancel_url: string
  update_url: string
  next_bill_date: string // YYYY-MM-DD
}

export interface SubscriberModel {
  name: string
  id: string
  subscription: SubscriptionModel
  paddle?: PaddleModel
  type: SUBSCRIBER_TYPE
  created_on: number
  updated_on: number
}
