import {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import {PopoverPicker} from './PopoverPicker'

export const ColorChangeInputGroup = ({property, color, setColor}) => {
  const [internalColor, _setInternalColor] = useState(color)

  function setInternalColor(color: string) {
    _setInternalColor(color)
    setColor(property, color)
  }

  useEffect(() => {
    _setInternalColor(color)
  }, [color])

  const onColorChange = (e) => {
    setColor(property, e.currentTarget.value)
    setInternalColor(e.currentTarget.value)
  }

  return (
    <InputGroup className='mb-3'>
      <Form.Control type='text' placeholder='#798989' value={color} onChange={onColorChange} />
      <PopoverPicker color={internalColor} onChange={setInternalColor} />
    </InputGroup>
  )
}
