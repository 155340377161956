import { Action } from '@reduxjs/toolkit'

import { put, takeLatest, select, call } from 'redux-saga/effects'
import { getLeadsByPage, updateLead, downloadCSV } from "./LeadsCRUD";
import { LeadModel } from '../models/LeadModel'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

function parseDateToUnixTimestamp(s: string) {
  let b: string[] = s.split(/\D/);
  // @ts-ignore
  let date = new Date(b[0], --b[1], b[2]);
  return Math.floor(date.getTime() / 1000);
}

export const actionTypes = {
  // Fetch via API
  FetchPage: '[FetchPage] Action',
  // Set the page fetched via API
  SetPage: '[SetPage] Action',
  DownloadCsv: '[DownloadCsv] Action',
  UpdateLead: '[UpdateLead] Action'
}

const initialLeadsState: ILeadsState = {
  currentPage: 1,
  leads: [],
  totalLeads: 0,
  leadsPerPage: 10,
  visiblePageList: [],
  columns: [],
  totalPages: 0
}

export interface ILeadsState {
  currentPage: number
  totalLeads: number
  leads: LeadModel[]
  leadsPerPage: number
  visiblePageList: number[]
  columns: string[]
  totalPages: number
}

export const reducer = (state: ILeadsState = initialLeadsState, action: ActionWithPayload<ILeadsState>) => {
  switch (action.type) {
    case actionTypes.SetPage: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

export const actions = {
  fetchPage: (currentPage: number) => ({ type: actionTypes.FetchPage, payload: { currentPage } }),
  setPage: (leads: LeadModel[],
    currentPage: number,
    totalLeads: number,
    visiblePageList: number[],
    totalPages: number,
    columns: string[]) => ({
      type: actionTypes.SetPage,
      payload: { leads, currentPage, totalLeads, visiblePageList, totalPages, columns },
    }),
  downloadCSV: (from: string, to: string) => ({ type: actionTypes.DownloadCsv, payload: { from, to } }),
  updateLead: (guestId: string, data: any) => ({ type: actionTypes.UpdateLead, payload: { guestId, data } })
}

const moveInArray = (arr: string[], from: number, to: number) => {

  // Make sure a valid array is provided
  if (Object.prototype.toString.call(arr) !== '[object Array]') {
    throw new Error('Please provide a valid array');
  }

  // Delete the item from it's current position
  var item = arr.splice(from, 1);

  // Make sure there's an item to move
  if (!item.length) {
    throw new Error('There is no item in the array at index ' + from);
  }

  // Move the item to its new position
  arr.splice(to, 0, item[0]);

};

const findIndex = (arr: string[], keyword: string) => {
  let foundIndex: number = -1;
  // Bring Name column to first index
  for (let index = 0; index < arr.length; index++) {
    if (arr[index].toLowerCase() === keyword) {
      foundIndex = index;
    }
  }
  return foundIndex;
}

// The download function takes a CSV string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called
var download = function (content: string, fileName: string, mimeType: string) {
  var a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (URL && 'download' in a) { //html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], {
      type: mimeType
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    // eslint-disable-next-line no-restricted-globals
    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
}


export function* saga() {

  yield takeLatest(actionTypes.UpdateLead, function* updateLeadHandler(action: ActionWithPayload<any>) {

    // @ts-ignore
    const stateSelectFn = (state) => {
      return {
        subscriberId: state.auth.user.subscriberId,
      }
    };

    // @ts-ignore
    let selectedState: any = yield select(stateSelectFn);

    yield updateLead(selectedState.subscriberId, action.payload.guestId, action.payload.data);

  })

  yield takeLatest(actionTypes.DownloadCsv, function* downloadCSVHandler(action: ActionWithPayload<any>) {
    // @ts-ignore
    const stateSelectFn = (state) => {
      return {
        subscriberId: state.auth.user.subscriberId,
        authToken: state.auth.authToken.id
      }
    };

    // @ts-ignore
    let selectedState: any = yield select(stateSelectFn);

    let from = parseDateToUnixTimestamp(action.payload.from);
    let to = parseDateToUnixTimestamp(action.payload.to);

    const { data: response, headers } = yield downloadCSV(selectedState.subscriberId, from, to, selectedState.authToken);
    console.log(response);

    download(response, `leads-${new Date().toDateString()}.csv`, 'text/csv;encoding:utf-8');
  })

  yield takeLatest(actionTypes.FetchPage, function* pageRequested(action: ActionWithPayload<any>) {
    // @ts-ignore
    const stateSelectFn = (state) => {
      return {
        subscriberId: state.auth.user.subscriberId,
        leadsPerPage: state.leads.leadsPerPage,
        visiblePageList: state.leads.visiblePageList as number[],
        columns: state.leads.columns as string[]
      }
    };
    // @ts-ignore
    let requestedPage: number = action.payload.currentPage;
    let selectedState: any = yield select(stateSelectFn)
    const { data: leads, headers } = yield getLeadsByPage(selectedState.subscriberId, requestedPage);

    let totalLeads: number = parseInt(headers['x-total-count'])
    let leadsPerPage: number = selectedState.leadsPerPage;
    let totalPages: number = Math.floor(totalLeads / leadsPerPage) + Math.ceil((totalLeads % leadsPerPage) / leadsPerPage);

    let visiblePageList: number[] = [];

    let minVisible: number = 1;
    let maxVisible: number = Math.min(5, totalPages);
    // Update visible page list
    if (requestedPage < selectedState.visiblePageList[0]) {
      minVisible = requestedPage
      maxVisible = Math.min(minVisible + 4, totalPages);
    } else if (requestedPage > selectedState.visiblePageList[selectedState.visiblePageList.length - 1]) {
      maxVisible = Math.min(requestedPage, totalPages);
      minVisible = Math.max(1, requestedPage - 4);
    }

    for (let index = minVisible; index <= maxVisible; index++) {
      visiblePageList.push(index);
    }

    let columns: string[] = [];
    // Calculate columns on first fetch
    if (requestedPage === 1 && totalLeads > 0) {
      let formKeysArray = leads.map((lead: LeadModel) => Object.keys(lead.form));
      let headers = new Set<string>();
      formKeysArray.forEach((keyArray: string[]) => { keyArray.forEach((key: string) => headers.add(key)) });
      let tempColumns: string[] = Array.from(headers);


      let nameIndex: number = findIndex(tempColumns, "name");

      if (nameIndex >= 0) {
        moveInArray(tempColumns, nameIndex, 0);
      }

      let contactIndex: number = findIndex(tempColumns, "mobile");
      if (contactIndex >= 0) {
        moveInArray(tempColumns, contactIndex, 1);
      }

      tempColumns.push("Notes");

      columns = tempColumns;

    } else {
      columns = selectedState.columns;
    }
    yield put(actions.setPage(leads, action.payload.currentPage, totalLeads, visiblePageList, totalPages, columns));
  })
}
