import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select} from 'redux-saga/effects'
import {UserModel} from '../../auth/models/UserModel'
import {SubscriberModel} from '../models/SubscriberModel'
import {SubscriptionModel} from '../models/SubscriptionModel'
import {getSubscriberById} from './SubscriberCRUD'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Save: '[Save] Action',
  Get: '[Get] Action',
}

export interface ISubscriberState {
  subscriber?: SubscriberModel
}

const initialSubscriberState: ISubscriberState = {
  subscriber: undefined,
}

export const reducer = persistReducer(
  {storage, key: 'engage-subscriber'},
  (
    state: ISubscriberState = initialSubscriberState,
    action: ActionWithPayload<ISubscriberState>
  ) => {
    switch (action.type) {
      case actionTypes.Save: {
        const subscriber = action.payload?.subscriber
        return {subscriber}
      }
      default:
        return state
    }
  }
)

export const actions = {
  save: (subscriber: SubscriberModel) => ({type: actionTypes.Save, payload: {subscriber}}),
  // Only call this after auth.user is set on the state
  get: () => ({type: actionTypes.Get}),
}

export function* saga() {
  yield takeLatest(actionTypes.Get, function* getSaga() {
    // @ts-ignore
    const getUser = (state) => state.auth.user
    // @ts-ignore

    let user: UserModel = yield select(getUser)
    const respone = yield getSubscriberById(user.subscriberId)

    const subscriber: SubscriberModel = respone.data

    yield put(actions.save(subscriber))
  })
}
