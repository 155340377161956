/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup';
import { actions } from './redux/StatsRedux';
import {
  StatisticsWidget7,
  ChartsWidget3
} from '../../../_metronic/partials/widgets'
import { IStatsState } from './redux/StatsRedux';

const DashboardPage: FC = () => {
  const dispatch = useDispatch()

  const statState: IStatsState = useSelector<RootState>(({ stats }) => stats, shallowEqual) as IStatsState;

  useEffect(() => {
    dispatch(actions.fetchStats())
  }, [dispatch]);

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget7
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
            color='danger'
            iconColor='white'
            title='Today'
            statCount={statState.aggregate.today}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget7
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='primary'
            iconColor='white'
            title='Last 7 Days'
            statCount={statState.aggregate.lastSevenDays}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget7
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra005.svg'
            color='success'
            iconColor='white'
            title='Last 30 Days'
            statCount={statState.aggregate.lastThirtyDays}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget7
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
            color='warning'
            iconColor='white'
            title='Total'
            statCount={statState.aggregate.total}
          />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <ChartsWidget3 className='card-xl-stretch mb-xl-8' stats={statState.stats} />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
