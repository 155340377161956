import axios from 'axios'
import {UserModel} from '../models/UserModel'
import {AuthToken} from '../models/AuthToken'
import {SubscriberModel} from '../../subscriber/models/SubscriberModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = (authToken: AuthToken) =>
  `${API_URL}/Agents/${authToken.userId}?access_token=${authToken.id}`
export const LOGIN_URL = `${API_URL}/Agents/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthToken
export function login(email: string, password: string) {
  return axios.post<AuthToken>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(authToken: AuthToken) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL(authToken))
}

export function getSubscriberById(id: string) {
  return axios.get<SubscriberModel>(`${API_URL}/Subscribers/${id}`)
}
