export interface JobModel {
  apifyTaskRun?: ApifyTaskRun
  stage: JOB_STAGE
  retryCount?: number
  created_on?: number
  updated_on?: number
  id?: string
  subscriberId?: string
  url: string
  urls?: string[]
}

export enum JOB_STAGE {
  CREATED = 'CREATED',
  CRAWL_IN_PROGRESS = 'CRAWL_IN_PROGRESS',
  CRAWL_SUCCESSFUL = 'CRAWL_SUCCESSFUL',
  TRAINING_IN_PROGRESS = 'TRAINING_IN_PROGRESS',
  TRAINING_SUCCESSFUL = 'TRAINING_SUCCESSFUL',
  FAILED = 'FAILED',
}

export interface ApifyTaskRun {
  id: string
  actId: string
  userId: string
  actorTaskId: string
  startedAt: Date
  finishedAt: Date
  status: string
  statusMessage: string
  isStatusMessageTerminal: boolean
  meta: Meta
  stats: {[key: string]: number}
  options: Options
  buildId: string
  exitCode: number
  defaultKeyValueStoreId: string
  defaultDatasetId: string
  defaultRequestQueueId: string
  buildNumber: string
  containerUrl: string
  usage: {[key: string]: number}
  usageTotalUsd: number
  usageUsd: {[key: string]: number}
}

export interface Meta {
  origin: string
  userAgent: string
}

export interface Options {
  build: string
  timeoutSecs: number
  memoryMbytes: number
  diskMbytes: number
}
