import axios from 'axios'
import {SubscriptionPlan} from '../models/PaddleTypes'
import {SubscriberModel} from '../../subscriber/models/SubscriberModel'
import {ISubscriberState} from '../../subscriber'

const API_URL = process.env.REACT_APP_API_URL

export function getSubscriptionPlans(subscriberState: ISubscriberState) {
  return axios.get<SubscriptionPlan[]>(`${API_URL}/plans`, {
    params: {
      'filter[where][and][0][subscriberType]': subscriberState.subscriber?.type,
      'filter[where][and][1][isInternal]': 'false',
    },
  })
}
