export enum PLAN {
  FREE = 'FREE', // There is no free plan offered on 17th Feb 2023
  STANDARD = 'STANDARD',
  PRO = 'PRO',
}

export enum PLAN_STATUS {
  ACTIVE = 'ACTIVE',
  PENDING_DEACTIVATION = 'PENDING_DEACTIVATION',
  INACTIVE = 'INACTIVE', // Plans become inactive when payment is not recieved.
  TRIAL = 'TRIAL', // Standard and pro plans start as trial and become active on payment
  TRIAL_EXPIRED = 'TRIAL_EXPIRED', // Trial expires after 30 days
}
export interface SubscriptionModel {
  plan: string // ID of plan object in lb3
  status: PLAN_STATUS
  rPaySubscriptionId?: string
}
