export interface RazorpaySuccesshandlerArgs {
  razorpay_payment_id: string
  razorpay_signature: string
  razorpay_subscription_id: string
}

export interface RazorpayErrorResponse {
  code: string
  description: string
  source: string
  step: string
  reason: string
  metadata: Metadata
}

export interface Metadata {
  payment_id: string
  order_id: string
}

export enum PaymentStatus {
  NOT_STARTED,
  IN_PROGRESS,
  SUCCESS,
  FAILED,
}
