import {Action} from '@reduxjs/toolkit'
import {put, takeLatest, select} from 'redux-saga/effects'
import {cancelRPaySubscription, createRPaySubscription, verifyRPaySubscription} from './PaymentCRUD'
import {SubscriberModel} from '../../subscriber/models/SubscriberModel'
import {actions as subscriberActions} from '../../subscriber/redux/SubscriberRedux'
import {
  PaymentStatus,
  RazorpayErrorResponse,
  RazorpaySuccesshandlerArgs,
} from '../models/RazorpayTypes'

const loadScript = (src: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const startPaymentRPay = (subscriber: SubscriberModel) => {
  return new Promise<RazorpaySuccesshandlerArgs | RazorpayErrorResponse>((resolve, reject) => {
    // @ts-ignore
    var options: RazorpayOptions = {
      key: process.env.REACT_APP_RPAY_KEY_ID!,
      subscription_id: subscriber.subscription.rPaySubscriptionId,
      name: subscriber.name,
      description: 'Monthly Test Plan',
      image: '/your_logo.jpg',
      handler: function (response: RazorpaySuccesshandlerArgs) {
        // Dispatch verify payment
        resolve(response)
      },
      theme: {
        color: '#F37254',
      },
    }

    //@ts-ignore
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
    paymentObject.on('payment.failed', function* (response: RazorpayErrorResponse) {
      console.error(response)
      reject(response)
    })
  })
}

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  StartPayment: '[StartPayment] Action',
  VerifyPayment: '[VerifyPayment] Action',
  UpdatePaymentStatus: '[UpdatePaymentStatus] Action',
  CancelSubscription: '[CancelSubscription] Action',
}

const initialPaymentState: IPaymentState = {
  paymentStatus: PaymentStatus.NOT_STARTED,
}

export interface IPaymentState {
  paymentStatus: PaymentStatus
}

export const reducer = (
  state: IPaymentState = initialPaymentState,
  action: ActionWithPayload<IPaymentState>
): IPaymentState => {
  switch (action.type) {
    case actionTypes.StartPayment: {
      return {paymentStatus: PaymentStatus.IN_PROGRESS}
    }
    case actionTypes.VerifyPayment: {
      return {paymentStatus: PaymentStatus.IN_PROGRESS}
    }
    case actionTypes.UpdatePaymentStatus: {
      const paymentStatus = action.payload!.paymentStatus
      return {...state, paymentStatus}
    }
    default:
      return state
  }
}

export const actions = {
  startPayment: () => ({type: actionTypes.StartPayment}),
  verifyPayment: (razorpayResponse: RazorpaySuccesshandlerArgs) => ({
    type: actionTypes.VerifyPayment,
    payload: razorpayResponse,
  }),
  updatePaymentStatus: (paymentStatus: PaymentStatus) => ({
    type: actionTypes.UpdatePaymentStatus,
    payload: {paymentStatus},
  }),
  cancelSubscription: () => ({
    type: actionTypes.CancelSubscription,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.CancelSubscription, function* cancelSubscriptionSaga() {
    // @ts-ignore
    const getUser = (state) => state.auth.user
    // @ts-ignore

    let user: UserModel = yield select(getUser)
    try {
      const response = yield cancelRPaySubscription(user.subscriberId)

      const subscriber: SubscriberModel = response.data

      // Save new subscriber
      yield put(subscriberActions.save(subscriber))
    } catch (error) {
      console.error('Error cancelling subscription')
      console.error(error)
    }
  })

  yield takeLatest(actionTypes.StartPayment, function* startPaymentSaga() {
    // @ts-ignore
    const getUser = (state) => state.auth.user
    // @ts-ignore

    let user: UserModel = yield select(getUser)
    try {
      const response = yield createRPaySubscription(user.subscriberId)

      const subscriber: SubscriberModel = response.data

      // Save new subscriber
      yield put(subscriberActions.save(subscriber))

      //@ts-ignore
      if (!window.Razorpay) {
        yield loadScript('https://checkout.razorpay.com/v1/checkout.js')
      }

      const paymentResponse: RazorpaySuccesshandlerArgs = yield startPaymentRPay(subscriber)
      yield put(actions.verifyPayment(paymentResponse))
    } catch (error) {
      console.error('Payment gateway failed')
      yield put(actions.updatePaymentStatus(PaymentStatus.FAILED))
    }
  })

  yield takeLatest(
    actionTypes.VerifyPayment,
    function* verifyPaymentSaga(action: ActionWithPayload<RazorpaySuccesshandlerArgs>) {
      // @ts-ignore
      const getUser = (state) => state.auth.user
      // @ts-ignore

      let user: UserModel = yield select(getUser)

      try {
        const response = yield verifyRPaySubscription(user.subscriberId, action.payload!)

        const subscriber: SubscriberModel = response.data

        yield put(actions.updatePaymentStatus(PaymentStatus.SUCCESS))
        // Save new subscriber
        yield put(subscriberActions.save(subscriber))
      } catch (e) {
        console.error('Payment verification failed')
        yield put(actions.updatePaymentStatus(PaymentStatus.FAILED))
      }
    }
  )
}
