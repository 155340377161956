import {Action} from '@reduxjs/toolkit'
import {put, takeLatest, select} from 'redux-saga/effects'
import {SubscriberModel} from '../../subscriber/models/SubscriberModel'
import store from '../../../../setup/redux/Store'
import {PaddleCallback, SubscriptionPlan} from '../models/PaddleTypes'
import {getSubscriptionPlans} from './PaddleCRUD'
import {AxiosResponse} from 'axios'
import {actions as modalActions} from '../../../../_metronic/partials/modals/redux/ModalRedux'

const loadScript = (src: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const startPaddlePayment = (subscriber: SubscriberModel) => {}

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetSubscriptionPlans: '[Paddle-GetSubscriptionPlans] Action',
  UpdateState: '[Paddle-UpdateState] Action',
  StartCheckout: '[Paddle-StartCheckout] Action',
}

const initialPaymentState: IPaddleState = {
  // paymentStatus: PaymentStatus.NOT_STARTED,
  plans: [],
}

export interface IPaddleState {
  plans: SubscriptionPlan[]
}

export const reducer = (
  state: IPaddleState = initialPaymentState,
  action: ActionWithPayload<IPaddleState>
): IPaddleState => {
  switch (action.type) {
    case actionTypes.UpdateState: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export const actions = {
  getSubscriptionPlans: () => ({type: actionTypes.GetSubscriptionPlans}),
  updateState: (state: IPaddleState) => ({
    type: actionTypes.UpdateState,
    payload: state,
  }),
  startCheckout: (plan) => ({type: actionTypes.StartCheckout, payload: plan}),
}

export function* saga() {
  yield takeLatest(
    actionTypes.StartCheckout,
    function* startCheckoutFunc(action: ActionWithPayload<any>) {
      const plan: SubscriptionPlan = action.payload

      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
          email: state.auth.user.email,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)

      //@ts-ignore
      window.Paddle.Checkout.open({
        product: plan.paddleId,
        email: selectedState.email,
        successCallback: 'checkoutComplete', // These are defined in store.ts
        closeCallback: 'checkoutClosed',
        // Engage Plan ID. Not paddle product id.
        customData: {subscriberId: selectedState.subscriberId, planId: plan.id},
      })
    }
  )
  yield takeLatest(
    actionTypes.GetSubscriptionPlans,
    function* getSubscriptions(action: ActionWithPayload<any>) {
      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
          subscriber: state.subscriber,
          paddle: state.paddle,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)

      try {
        //@ts-ignore
        if (!window.Paddle) {
          yield loadScript('https://cdn.paddle.com/paddle/paddle.js')
          //@ts-ignore
          window.Paddle.Environment.set(process.env.REACT_APP_PADDLE_ENV)
          //@ts-ignore
          window.Paddle.Setup({vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID)})
        }

        const axiosResponse: AxiosResponse = yield getSubscriptionPlans(selectedState.subscriber)

        const plans: SubscriptionPlan[] = axiosResponse.data

        // If plan did not get paddle injected, try again.
        if (plans && plans.length > 0 && !plans[0].paddle) {
          setTimeout(() => {
            put(actions.getSubscriptionPlans())
          }, 1000)
          return
        }

        yield put(
          actions.updateState({
            plans: plans,
          })
        )
      } catch (e) {
        console.error(e)
      }
    }
  )
}
