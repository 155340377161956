import {Action} from '@reduxjs/toolkit'

import {put, takeLatest, select} from 'redux-saga/effects'
import {getBlueprint, postBlueprint} from './PromptsCRUD'
import {PromptsModel} from '../models/BlueprintModel'
import {toast} from 'react-toastify'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components/_ScrollTopComponent'
import {AxiosResponse} from 'axios'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}
const emptyFile = new File([], 'empty')

export const actionTypes = {
  // Get blueprint
  GetBlueprint: '[GetBlueprint] Action',
  // Update blueprint on redux state
  UpdateBlueprintState: '[UpdateBlueprint] Action',
  UpdateBlueprintInBackend: '[UpdateBlueprintInBackend] Action',
}

export interface IBlueprintState {
  data: PromptsModel
  iframeKey: number
}

const initialSettingsState: IBlueprintState = {
  data: {
    bubble_data: '',
    placeholder: '',
    suggestions: [],
  },
  iframeKey: 0,
}

export const reducer = (
  state: IBlueprintState = initialSettingsState,
  action: ActionWithPayload<IBlueprintState>
) => {
  switch (action.type) {
    case actionTypes.UpdateBlueprintState: {
      return {...state, ...action.payload}
    }
    default:
      return state
  }
}

export const actions = {
  getBlueprint: () => ({type: actionTypes.GetBlueprint}),
  // Does not update on the backend. Update the redux state
  updateBlueprintInBackend: () => ({
    type: actionTypes.UpdateBlueprintInBackend,
  }),
  updateBlueprintState: (blueprintState: IBlueprintState) => ({
    type: actionTypes.UpdateBlueprintState,
    payload: blueprintState,
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.GetBlueprint,
    function* getBlueprintFunc(action: ActionWithPayload<any>) {
      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
          prompts: state.prompts,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)

      const axiosResponse: AxiosResponse = yield getBlueprint(selectedState.subscriberId)

      const prompt: PromptsModel = axiosResponse.data

      yield put(
        actions.updateBlueprintState({
          data: {...selectedState.prompts.data, ...prompt},
          iframeKey: 0,
        })
      )
    }
  )

  /**
   * Updates settings in backend -> Updates settings state
   */
  yield takeLatest(
    actionTypes.UpdateBlueprintInBackend,
    function* updateBlueprintFunc(action: ActionWithPayload<any>) {
      // @ts-ignore
      const stateSelectFn = (state) => {
        return {
          subscriberId: state.auth.user.subscriberId,
          prompts: state.prompts,
        }
      }
      // @ts-ignore
      let selectedState: any = yield select(stateSelectFn)

      var toastId
      try {
        // Upload logo file

        toastId = toast.loading('Saving ...', {autoClose: 2000, theme: 'colored'})

        const {data: prompt} = yield postBlueprint(
          selectedState.subscriberId,
          selectedState.prompts.data
        )

        yield put(
          actions.updateBlueprintState({
            data: {...selectedState.prompts.data, ...prompt},
            iframeKey: selectedState.prompts.iframeKey + 1,
          })
        )

        toast.update(toastId, {
          render: 'Saved',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
      } catch (error) {
        toast.update(toastId, {
          render: 'An error occurred.',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
      }
    }
  )
}
